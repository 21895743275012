import React from "react"
import { useEffect } from "react"
import { navigate } from "gatsby"

const Template = () => {
  useEffect(() => {
    navigate("/signup?utm_source=missingpage", { replace: true })
  }, [])

  return <div>&nbsp;</div>
}

export default Template
